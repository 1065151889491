import { defineMessages } from 'react-intl';

export const scope = 'modal';

export default defineMessages({
  buttonYes: {
    id: `${scope}.button.yes`,
    defaultMessage: 'Yes',
  },
  buttonCancel: {
    id: `${scope}.button.cancel`,
    defaultMessage: 'Cancel',
  },
});
