import { defineMessages } from 'react-intl';

export const scope = 'layouts';

export default defineMessages({
  labelButtonLogout: {
    id: `${scope}.label.button.logout`,
    defaultMessage: 'Logout',
  },
  labelButtonLegal: {
    id: `${scope}.label.button.legal`,
    defaultMessage: 'Legal',
  },
  menuItemHome: {
    id: `${scope}.menu.home`,
    defaultMessage: 'Home',
  },
  menuItemDashboard: {
    id: `${scope}.menu.dashboard`,
    defaultMessage: 'Dashboard',
  },
  menuItemMyMelodies: {
    id: `${scope}.menu.myMelodies`,
    defaultMessage: 'My Melodies',
  },
  menuItemMyAccount: {
    id: `${scope}.menu.myaccount`,
    defaultMessage: 'My Account',
  },
  menuItemInfluencer: {
    id: `${scope}.menu.influencer`,
    defaultMessage: 'Affiliate',
  },
  menuItemCreators: {
    id: `${scope}.menu.creators`,
    defaultMessage: 'Loop Creator Info',
  },
  menuItemPreferences: {
    id: `${scope}.menu.preferences`,
    defaultMessage: 'Filters',
  },
  menuItemSavedMelodies: {
    id: `${scope}.menu.bookmarks`,
    defaultMessage: 'Saved Melodies',
  },
  menuItemStatistics: {
    id: `${scope}.menu.statistics`,
    defaultMessage: 'Statistics',
  },
  menuItemSubscriptions: {
    id: `${scope}.menu.subscriptions`,
    defaultMessage: 'Subscriptions',
  },
  menuItemUsers: {
    id: `${scope}.menu.users`,
    defaultMessage: 'Users',
  },
  logout: {
    id: `${scope}.menu.logout`,
    defaultMessage: 'Logout',
  },
  legal: {
    id: `${scope}.menu.legal`,
    defaultMessage: 'Terms & Conditions',
  },
  privacy: {
    id: `${scope}.menu.privacy`,
    defaultMessage: 'Privacy Policy',
  },
  support: {
    id: `${scope}.menu.support`,
    defaultMessage: 'Support',
  },
  contactus: {
    id: `${scope}.menu.contactus`,
    defaultMessage: 'Support',
  },
  logoutDialogTitle: {
    id: `${scope}.dialog.confirmLogout`,
    defaultMessage: 'App Logout',
  },
  logoutDialogText: {
    id: `${scope}.dialog.confirmLogoutText`,
    defaultMessage: 'Are you sure, you want to logout from the app?',
  },
});
