import ApiClient from 'configs/axios';
import mixpanel from 'mixpanel-browser';
/**
 * Send request for login user to the system
 * @param token
 * @returns {AxiosPromise<any>}
 */
export function auth(provider, token, inviteCode) {
  console.log('auth', inviteCode);

  return ApiClient.post(`/socialauth/${provider}`, {
    inviteCode
  }, {
    headers: {
      // @ts-ignore
      common: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
}

/**
 * Send request for login user to the system with login/password
 * @param data
 * @returns {AxiosPromise<any>}
 */
export function plainAuth(data) {
  return ApiClient.post(`/login`, data);
}

/**
 * Send request to reload the user
 * @param data
 * @returns {AxiosPromise<any>}
 */
export function reloadUser() {
  return ApiClient.get('/user/self');
}

/**
 * Send request to update the user
 * @param data
 * @returns {AxiosPromise<any>}
 */
export function updateUser(data) {
  mixpanel.track('Update User', { data });
  return ApiClient.put('/user/self', data);
}

/**
 * Send request to get the user
 * @returns {AxiosPromise<any>}
 */
export function logoutUser() {
  return ApiClient.post(`/logout`);
}
