import React, { memo } from 'react';

import { DialogContent, DialogContentProps, makeStyles } from '@material-ui/core';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends DialogContentProps {
  children: React.ReactNode;
}

const ModalContent = ({ children, ...DialogContentProps }: Props) => {
  const classes = useStyles();

  return (
    <DialogContent className={classes.modalContent} {...DialogContentProps}>
      {children}
    </DialogContent>
  );
};

export default memo(ModalContent);
