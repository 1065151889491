import { createStyles, Theme } from '@material-ui/core/styles';
import {
  bgPaper,
  primaryColor,
  primaryColorDark,
  secondaryColor,
  textPrimaryColor,
  layoutHorizontalIndent,
  secondaryColorDark,
  textSecondaryColor,
  drawerWidth,
  borderSecondaryColor,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    rootContainer: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },

    // Header
    header: {
      ...layoutHorizontalIndent,
      paddingTop: 15,
    },
    absolute: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
    },
    default: {
      paddingBottom: 5,
      display: 'flex',
      alignItems: 'center',
    },
    menuButtonHidden: {
      visibility: 'hidden',
    },
    menuButton: {
      color: textSecondaryColor,
      padding: 3,
      fontSize: 40,

      '& svg': {
        fontSize: 'inherit',
      },
    },

    // Main
    mainContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      maxWidth: '100%',
      minWidth: 0,
    },
    mainContentIndent: {
      ...layoutHorizontalIndent,
    },
    mainContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 10,
      paddingBottom: 20,

      [theme.breakpoints.up('md')]: {
        paddingTop: 60,
        paddingBottom: 30,
      },
    },

    // Sidebar
    sidebar: {
      flexShrink: 0,
      position: 'relative',

      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
      },
    },
    sidebarPaper: {
      color: textPrimaryColor,
      backgroundColor: bgPaper,
      border: 'none !important',
      width: '100%',

      [theme.breakpoints.up('md')]: {
        left: 'auto',
        width: drawerWidth,
      },
    },
    sidebarContent: {
      flexGrow: 1,
      paddingTop: 16,
      paddingBottom: 20,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 30,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 50,
      },
    },
    sidebarLogoWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...layoutHorizontalIndent,
      paddingBottom: 30,

      [theme.breakpoints.up('sm')]: {
        paddingBottom: 50,
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        paddingBottom: 80,
      },
    },
    sidebarLogo: {
      '& img': {
        width: 153,
        height: 36,
        [theme.breakpoints.up('md')]: {
          width: 179,
          height: 42,
        },
      },
    },
    sidebarCloseButton: {
      marginRight: -8,
    },
    sidebarNav: {},
    sidebarNavItem: {
      color: textPrimaryColor,
      alignItems: 'center',
      ...layoutHorizontalIndent,
      paddingTop: 8,
      paddingBottom: 8,
      position: 'relative',
      borderBottom: `1px solid ${borderSecondaryColor}`,

      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: 3,
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: secondaryColor,
        '&::after': {
          backgroundColor: primaryColorDark,
        },
      },
      '&.active': {
        '&, &:hover': {
          backgroundColor: secondaryColorDark,
          '&::after': {
            backgroundColor: primaryColor,
          },
        },
      },
    },
    sidebarNavIcon: {
      minWidth: 24,
      marginRight: 10,
    },
    sidebarNavTextWrapper: {
      margin: 0,
    },
    sidebarNavText: {
      fontSize: 18,
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },

    sidebarSocialBlock: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: -8,
    },
    sidebarSocialButton: {
      '&:not(:last-child)': {
        marginRight: 3,
      },
    },
    sidebarSocialButtonInstagram: {
      color: '#FF78AD',
    },
    sidebarSocialButtonFacebook: {
      color: '#3B5998',
    },
    sidebarSocialButtonTwitter: {
      color: '#78C7E3',
    },
    sidebarSocialButtonYouTube: {
      color: '#FF4949',
      '& svg': {
        fontSize: 24,
      },
    },
    sidebarSocialButtonTwitch: {
      color: '#9146FF',
    },
    sidebarFooter: {
      ...layoutHorizontalIndent,
      paddingTop: 16,
      paddingBottom: 10,
    },
    sidebarFooterDivider: {
      marginTop: 5,
      marginBottom: 7,
    },
    sidebarFooterMenuBlock: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -10,
    },
    sidebarFooterMenuItem: {
      width: '50%',
      padding: 2,
      '&:not(:last-child)': {
        marginBottom: 3,
      },
    },
    sidebarFooterMenuButton: {
      fontSize: 14,
      fontWeight: 400,
      padding: '2px 8px',
      minWidth: 0,
    },
  });
