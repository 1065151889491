import { createFormAction } from 'redux-form-saga';
import { createAction } from 'redux-actions';

export const loginAction = createFormAction('AUTH_LOGIN');
export const logoutUserAction = createFormAction('AUTH_LOGOUT');
export const updateUserAction = createFormAction('USER_UPDATE');
export const authorizeUserAction = createAction('AUTHORIZE_USER');
export const setUserAction = createAction('SET_USER');
export const clearAuthAction = createAction('AUTH_CLEAR');
export const reloadUserAction = createAction('USER_RELOAD');
