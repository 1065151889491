const appConfig = {
  date: {
    timeFormat: 'h:mm A',
    dateFormat: 'MM/DD/YYYY',
    dateTimeFormat: 'MM/DD/YYYY h:mm A',
    dateShortFormat: 'MM/DD',
  },
  isProductionApi: process.env?.REACT_APP_API_HOST?.includes('https://www.api.app.themelodyapp.com'),
};

export { appConfig };
