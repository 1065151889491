import { ReactNode } from 'react';

import useModal, { Props as UseModalProps } from './useModal';
import Confirmation from '../components/Confirmation';

export interface Props extends Pick<UseModalProps, 'onSubmit' | 'onClose' | 'isSupportShowProps' | 'unmount'> {
  readonly title?: string;
  readonly description?: ReactNode;
  readonly agreeLabel?: string;
  readonly cancelLabel?: string;
}

const useConfirmation = ({
  title = 'Confirm',
  description = 'Are you sure?',
  agreeLabel,
  cancelLabel,
  ...rest
}: Props) => {
  return useModal({
    ...rest,
    //@ts-ignore
    Modal: Confirmation,
    props: { title, description, agreeLabel, cancelLabel },
  });
};

export default useConfirmation;
