import React from 'react';
import dayJs from 'dayjs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { appConfig } from 'configs/app';
import { primaryColor } from 'configs/variables';
import { Modal, ModalContent, ModalTitle } from 'app/components/ui/Modal';
import useAuth from 'app/components/auth/hooks/useAuth';

import styles from '../styles';

const useStyles = makeStyles(styles);

interface TrialModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
}

export const TrialModal = ({ open, onClose }: TrialModalProps) => {
  const classes = useStyles();

  const { user } = useAuth();

  const formattedExpiredAt = dayJs(user?.subscription?.expiredAt).format(appConfig.date.dateFormat);

  return (
    <Modal open={open} onBackdropClick={onClose} className={classes.trialModalPaper}>
      <ModalTitle onClose={onClose} content>
        <Typography variant="h1" style={{ fontWeight: 300 }}>
          Your subscription is active
        </Typography>
      </ModalTitle>
      <ModalContent>
        <Box>
          <Typography style={{ fontWeight: 700 }}>
            You will not be charged until your free trial ends on{' '}
            <span style={{ color: primaryColor }}>{formattedExpiredAt}</span>
          </Typography>
          <Typography style={{ fontWeight: 300 }}>
            To verify your billing information, we may issue a temporary $1 authorization charge. This charge will be
            voided as soon as your details are verified
          </Typography>
        </Box>
        <Box mt={3} maxWidth={300}>
          <Button fullWidth variant="contained" color="primary" onClick={onClose}>
            Start Swiping
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};
