import { LOW_BALANCE_FLAG_VARNAME } from '../configs/subscriptions';

export const EVENT_KEY = 'fwd_event';

export interface CallbacksMapping {
  [key: string]: (value: any) => void;
}

export interface NotificationMessage {
  readonly message: string;
  readonly type: 'success' | 'error' | 'default';
}

interface MessageMapping {
  readonly [key: string]: NotificationMessage;
}

const PARAMS_TO_MESSAGES_MAPPING: MessageMapping = {
  paymentOk: {
    message: 'Subscription Payment succeeded',
    type: 'success',
  },
  paymentCancel: {
    message: 'Subscription Payment declined',
    type: 'error',
  },
  emailConfirm: {
    message: 'Email confirmed successfully, now you can login using your credentials',
    type: 'success',
  },
  confirmError: {
    message: 'Unable to confirm email. Probably confirmation link is not valid anymore',
    type: 'error',
  },
};

const PARAMS_TO_CALLBACK_MAPPING: CallbacksMapping = {
  paymentOk: () => {
    localStorage.removeItem(LOW_BALANCE_FLAG_VARNAME);
  },
};

export function mapNotifications(queryParams: string): NotificationMessage | null {
  const params = new URLSearchParams(queryParams);
  if (!params.has(EVENT_KEY)) {
    return null;
  }
  const eventKey = params.get(EVENT_KEY);
  return eventKey && PARAMS_TO_MESSAGES_MAPPING.hasOwnProperty(eventKey) ? PARAMS_TO_MESSAGES_MAPPING[eventKey] : null;
}

export function mapCallbacks(queryParams: string): any | null {
  const params = new URLSearchParams(queryParams);
  if (!params.has(EVENT_KEY)) {
    return null;
  }
  const eventKey = params.get(EVENT_KEY);
  return eventKey && PARAMS_TO_CALLBACK_MAPPING.hasOwnProperty(eventKey)
    ? PARAMS_TO_CALLBACK_MAPPING[eventKey](params[eventKey])
    : null;
}
