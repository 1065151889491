import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) =>
  createStyles({
    pageBlock: {
      position: 'relative',
    },
    pageBlockRead: {
      width: 800,
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    pageCloseButton: {
      position: 'absolute',
      right: 0,
      '&$inner': {
        top: 0,
      },
      '&$outer': {
        bottom: '100%',
        [theme.breakpoints.down('sm')]: {
          marginBottom: 20,
        },
      },
    },
    inner: {},
    outer: {},
    pageHeader: {
      marginBottom: 30,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    pageHeaderBorder: {
      marginBottom: 15,
    },
    pageHeaderTitle: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    pageHeaderAction: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 20,
      },
    },
  });
