import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Logo from 'images/logo.svg';

import styles from '../styles/authorization';

const useStyles = makeStyles(styles);

export interface Props {
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export const LogoIcon: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.authorizationContent}>
      <div className={classes.authorizationLogo}>
        <img className={classes.authorizationLogoImage} src={Logo} alt="logo" />
      </div>
    </div>
  );
};
