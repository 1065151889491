/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

import guestPageDecorator from 'app/components/auth/decorators/guestonly.page.hoc';

export const PasswordRecoveryPage = lazyLoad(
  () => import('./index'),
  module => guestPageDecorator(module.PasswordRecoveryPage),
);
