import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, DialogActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import messages from '../messages';
import styles from '../styles';
import { ModalProps } from '../types';
import Modal from './Modal';
import ModalTitle from './ModalTitle';
import ModalContent from './ModalContent';

const useStyles = makeStyles(styles);

export interface Props extends ModalProps {
  readonly title: string;
  readonly description: ReactNode;
  readonly agreeLabel?: string;
  readonly cancelLabel?: string;
}

const Confirmation: React.FC<Props> = ({ open, onSubmit, onClose, title, description, agreeLabel, cancelLabel }) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modalConfirmation} open={open} onBackdropClick={onClose}>
      <ModalTitle className={classes.modalTitle} content onClose={onClose}>
        <Typography variant="h3">{title}</Typography>
      </ModalTitle>

      <ModalContent className={classes.modalContent}>{description}</ModalContent>

      <DialogActions className={classes.modalActions}>
        <Button
          className={classes.modalActionsButton}
          onClick={onClose}
          color="primary"
          variant="outlined"
          size="small"
        >
          {cancelLabel || <FormattedMessage {...messages.buttonCancel} />}
        </Button>
        <Button
          className={classes.modalActionsButton}
          onClick={onSubmit}
          color="primary"
          variant="contained"
          size="small"
        >
          {agreeLabel || <FormattedMessage {...messages.buttonYes} />}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default Confirmation;
