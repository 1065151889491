import ApiClient from 'configs/axios';
import mixpanel from 'mixpanel-browser';
/**
 * Get subscription plans
 * @param params
 * @returns {AxiosPromise<any>}
 */
export function getSubscriptionPlans(params: any = null) {
  const sfx = params ? new URLSearchParams(params).toString() : '';
  return ApiClient.get(`/subscriptions/plans${sfx ? `?${sfx}` : ''}`);
}

/**
 * Update subscription plan
 * @param id
 * @param data
 * @returns {AxiosPromise<any>}
 */
export function updateSubscriptionPlan(id: number, data: any) {
  mixpanel.track('Update Subscription Plan', { planId: id, data });
  return ApiClient.put(`/subscriptions/plans/${id}`, data);
}

/**
 * Send create checkout session request to stripe
 * @returns {AxiosPromise<any>}
 */
export function requestForSubscription(subsKey: string) {
  return ApiClient.post(`/subscriptions/subscribe`, { plan: subsKey, provider: 'stripe' });
}

/**
 * Update user subscription plan by admin
 * @param data
 * @returns {AxiosPromise<any>}
 */
export function assignSubscriptionPlan(data: any) {
  mixpanel.track('Assign Subscription Plan', { data });
  return ApiClient.post(`/subscriptions/assign`, data);
}

/**
 * Sync Data
 * @returns {AxiosPromise<any>}
 */
export function syncData() {
  return ApiClient.get(`/subscriptions/sync`);
}
