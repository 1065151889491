import { useModal } from 'app/components/ui/Modal';
import useAuth from 'app/components/auth/hooks/useAuth';

import { TrialModal } from '../components/TrialModal';

export const useTrialModal = () => {
  const { user } = useAuth();

  const [showModal, hideModal] = useModal({
    Modal: TrialModal,
  });

  const handleShow = () => {
    if (user && user?.isTrialAllowed) {
      showModal();
    }
  };

  return [handleShow, hideModal];
};
