import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';


import { Page } from 'app/components/layouts';
import Loader from 'app/components/ui/loader/components/Loader';
import { getCreators } from 'app/components/melody/api';
import { useEffectOnce } from 'react-use';
import './styles/index.scss';
import Button from '@material-ui/core/Button';
import { Link } from '@material-ui/core';

export function CreatorsPage() {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    getCreators()
      .then(
        ({ data }) => {
          setData(data);
          setLoading(false);
        },
        err => {},
      )
      .finally(() => {});
  });

  return (
    <Loader loading={loading}>
      <Helmet>
        <title>Creators</title>
        <meta name="description" content="Influencer Dashboard" />
      </Helmet>
      <Page title="Creators" className="influencerDashboard">
        <div className="creators">
          {data.map((item: any) => (
            <div className="creator">
              <div className="influencerCard">
                <div className="influencerCard__content">
                  <div className="top">
                    <h3>{item.name}</h3>
                   </div>
                  <p>{item.about}</p>
                  <Button color="primary"><Link href={`/creators/${item.id}`}>Learn More</Link></Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Page>
    </Loader>
  );
}
