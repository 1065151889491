import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { getRandomIcon } from 'configs/icons';

import { IconicMessage } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

interface IconPucherContextInterface {
  push: (iconName: string, options: any) => void;
}

export const IconPusherContext = React.createContext({
  push: (iconName: string, options: any) => {},
} as IconPucherContextInterface);

export function IconPusherProvider(props) {
  const classes = useStyles();
  const [icon, setIcon] = useState<any>(null);

  const pushIcon = useCallback((iconName: string, options: any = {}) => {
    const newIcon: IconicMessage = { id: uuidv4(), icon: iconName, timestamp: new Date().getTime(), options };
    setIcon(newIcon);
  }, []);

  return (
    <div className={cx(classes.iconActionProviderWrapper, 'md-full-height')}>
      {icon && (
        <SvgIcon
          className={cx(classes.iconAction, icon.options.className)}
          key={icon.id}
          component={getRandomIcon(icon.icon)}
          viewBox="0 0 134 134"
        />
      )}
      <IconPusherContext.Provider value={{ push: pushIcon }}>{props.children}</IconPusherContext.Provider>
    </div>
  );
}
