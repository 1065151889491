import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Page } from 'app/components/layouts';
import Loader from 'app/components/ui/loader/components/Loader';
import { getCreator } from 'app/components/melody/api';
import { useEffectOnce } from 'react-use';
import './styles/profile.scss';
import { useParams } from 'react-router-dom';

export function CreatorProfile(props) {
  const [data, setData] = useState<any>([]);
  const [loading] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const { id } = useParams();

  useEffectOnce(() => {
    getCreator(id)
      .then(
        ({ data }) => {
          setData(data);
        },
        err => {},
      )
      .finally(() => {});
  });

  return (
    <Loader loading={loading}>
      <Helmet>
        <title>Creator Profile</title>
        <meta name="description" content="Creator Profile" />
      </Helmet>
      <Page title="" className="CreatorProfile">
        <div className="creator">
          <h2>{data.name}</h2>
          <div className="info">
            <h5>About The Creator</h5>
            <p>{data.about}</p>
          </div>

          {data.metadata?.legal_name && (
            <div className="info">
              <h5>Full Legal Name</h5>
              <p>{data.metadata?.legal_name}</p>
            </div>
          )}

          {data.metadata?.producer_name && (
            <div className="info">
              <h5>Producer Name</h5>
              <p>{data.metadata?.producer_name}</p>
            </div>
          )}

          {data.metadata?.affiliation && (
            <div className="info">
              <h5>Writer PRO Affiliation</h5>
              <p>{data.metadata?.affiliation}</p>
            </div>
          )}

          {data.metadata?.writer_ipi_number && (
            <div className="info">
              <h5>Writer IPI Number</h5>
              <p>{data.metadata?.writer_ipi_number}</p>
            </div>
          )}

          {data.metadata?.publishing_company && (
            <div className="info">
              <h5>Publishing Company</h5>
              <p>{data.metadata?.publishing_company}</p>
            </div>
          )}

          {data.metadata?.publishing_pro && (
            <div className="info">
              <h5>Publishing Company PRO Affiliation</h5>
              <p>{data.metadata?.publishing_pro}</p>
            </div>
          )}

          {data.metadata?.publishing_company_ipi_number && (
            <div className="info">
              <h5>Publishing Company IPI Number</h5>
              <p>{data.metadata?.publishing_company_ipi_number}</p>
            </div>
          )}

          {data.metadata?.beatstars && (
            <div className="info">
              <h5>Website</h5>
              <a target="_blank" href={data.metadata?.beatstars}>{data.metadata?.beatstars}</a>
            </div>
          )}

          {data.metadata?.instagram && (
            <div className="info">
              <h5>Instagram</h5>
              <a target="_blank" href={data.metadata?.instagram}>{data.metadata?.instagram}</a>
            </div>
          )}

          {data.metadata?.credits_of_note && (
            <div className="info">
              <h5>Credits of Note</h5>
              <p>{data.metadata?.credits_of_note}</p>
            </div>
          )}
        </div>
      </Page>
    </Loader>
  );
}
