export enum PLAN {
  FREE = 'free',
  STANDARD = 'standard',
  PREMIUM = 'premium',
  UNLIMITED = 'unlimited',
}

export enum PROVIDER {
  STRIPE = 'stripe',
  NONE = 'none',
  IOS = 'ios',
  ANDROID = 'android',
}

export const PROVIDER_LINKS = {
  [PROVIDER.ANDROID]: 'https://play.google.com/store/account/subscriptions',
  [PROVIDER.IOS]: 'https://apps.apple.com/account/subscriptions',
  [PROVIDER.STRIPE]: '',
};

export const PROVIDER_NAMES = {
  [PROVIDER.ANDROID]: 'Android',
  [PROVIDER.IOS]: 'iOS',
  [PROVIDER.STRIPE]: 'Stripe',
};
