/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

import privatePageDecorator from 'app/components/auth/decorators/private.page.hoc';

export const MyAccount = lazyLoad(
  () => import('./index'),
  module => privatePageDecorator(module.MyAccount),
);
