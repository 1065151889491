import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import User from 'types/user.type';

import { DEFAULT_PAGE, SETTINGS_PAGE, UPLOADS_PAGE } from 'configs/application';
import {
  authorizeUserAction,
  clearAuthAction,
  loginAction,
  logoutUserAction,
  reloadUserAction,
  setUserAction,
  updateUserAction,
} from '../actions';
import { logoutUser, reloadUser, updateUser } from '../api';
import { ROLES } from 'app/components/acl/constants';
import { PLAN } from 'app/components/subscriptions/constants';

function* handleAuthorizeSaga(action) {
  try {
    const user = User.createFrom(action.payload).store();
    yield put(setUserAction(user.toPlain()));
    if (user.role === ROLES.Maker) {
      yield put(push(action.payload.firstLogin ? UPLOADS_PAGE : DEFAULT_PAGE));
    } else if ((user.role === ROLES.Searcher || user.role === ROLES.Influencer) && user?.subscription?.plan?.key !== PLAN.FREE) {
      yield put(push(!!user.preferences.tags ? DEFAULT_PAGE : SETTINGS_PAGE));
    } else {
      yield put(push(DEFAULT_PAGE));
    }
  } catch (e) {
    yield put(loginAction.failure(e));
  }
}

function* handleReloadUser() {
  try {
    const { data } = yield call(reloadUser);
    const user = User.createFrom(data).store();
    yield put(setUserAction(user.toPlain()));
  } finally {
    // Just do nothing
  }
}

export function* handleLogoutSaga() {
  try {
    const user = User.populate();

    yield call(logoutUser);
    yield put(clearAuthAction());
    yield put(logoutUserAction.success());

    if (user) {
      user.dispose();
    }

    yield put(push(DEFAULT_PAGE));
  } catch (e) {
    yield put(logoutUserAction.failure(e));
  }
}

export function* handleUpdateUser(action) {
  try {
    const { data } = yield call(updateUser, action.payload);
    const user = User.createFrom(data).store();
    yield put(updateUserAction.success(user.toPlain()));
  } catch (e) {
    yield put(updateUserAction.failure(e));
  }
}

export const authorizeWatcherSaga = {
  key: 'authorize',
  *saga() {
    yield takeLatest(authorizeUserAction, handleAuthorizeSaga);
  },
};

export const logoutUserWatcherSaga = {
  key: 'logoutUser',
  *saga() {
    yield takeLatest(logoutUserAction.REQUEST, handleLogoutSaga);
  },
};

export const updateUserWatcherSaga = {
  key: 'updateUser',
  *saga() {
    yield takeLatest(updateUserAction.REQUEST, handleUpdateUser);
  },
};

export const reloadUserWatcherSaga = {
  key: 'reloadUser',
  *saga() {
    yield takeLatest(reloadUserAction, handleReloadUser);
  },
};
