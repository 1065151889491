import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from 'serviceWorker';
import { history } from 'utils/history';

// Theme provide
import 'styles/scss/style.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import reportWebVitals from './reportWebVitals';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

// Import Language Provider
import LanguageProvider from 'app/components/common/LanguageProvider';

// Icon pusher:
import { IconPusherProvider } from 'app/components/common/IconPusherProvider';

// Load configs
import theme from 'configs/theme';

//Modal
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';

// Initialize languages
// import './locales/i18n';

import { setUserAction } from 'app/components/auth/actions';
import { reloadUser } from 'app/components/auth/api';
import User from 'types/user.type';
import mixpanel from 'mixpanel-browser';

import LogRocket from 'logrocket';
LogRocket.init('dfoj9l/the-melody-app');

// Create redux store with history
const store = configureAppStore(history);

// Set auth data to redux state if provided:
let user = User.populate();
if (user) {
  store.dispatch(setUserAction(user.toPlain()));
  // Reload(refresh) user data from API:
  reloadUser()
    .then(({ data }) => {
      // Update auth data:
      user = User.createFrom(data);
      store.dispatch(setUserAction(user.store().toPlain()));
    })
    .catch(err => {
      console.error('Unable to reload user data: ', err);
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
mixpanel.init('fcb679928b4dbd576f4c01bafcbefa58', { track_pageview: true, persistence: 'localStorage'});

root.render(
  <Provider store={store}>
    <LanguageProvider messages={{}}>
      {/*// @ts-ignore*/}
      <ConnectedRouter history={history}>
        {/* MuiThemeProvider makes the theme available down the React tree thanks to React context. */}
        <MuiThemeProvider theme={theme}>
          {/*// @ts-ignore*/}
          <HelmetProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              classes={{
                variantSuccess: 'snackbar-provider success',
              }}
              autoHideDuration={3000}
            >
              <IconPusherProvider>
                <ModalProvider rootComponent={TransitionGroup}>
                  <App />
                  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                  <CssBaseline />
                </ModalProvider>
              </IconPusherProvider>
            </SnackbarProvider>
          </HelmetProvider>
        </MuiThemeProvider>
      </ConnectedRouter>
    </LanguageProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === 'production') {
//   serviceWorker.register();
// } else {
serviceWorker.unregister();
// }
