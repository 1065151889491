import { createStyles, Theme } from '@material-ui/core/styles';

import bottomNavStyle from 'app/components/layouts/styles/views/bottomNav';
import editCellStyle from 'app/components/table/styles/editCell';
import {
  primaryColor,
  dangerColor,
  lightGreyColor,
  textSecondaryColor,
  transition,
  whiteColor,
  warningColor,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    ...bottomNavStyle(theme),
    ...editCellStyle(theme),
    subscriptionBox: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      color: lightGreyColor,
      border: `solid 1px ${lightGreyColor}`,
      borderRadius: 4,
      padding: '10px 5px',

      [theme.breakpoints.up('sm')]: {
        padding: '15px 10px',
      },
      '&:not(:last-child)': {
        marginBottom: 7,
      },
      '&$selected': {
        color: primaryColor,
        borderColor: primaryColor,
      },
      '&$disabled': {
        opacity: 0.45,
      },
    },
    selected: {},
    disabled: {},
    subscriptionBoxContent: {
      marginLeft: 5,
    },
    planUserName: {
      color: whiteColor,
      fontSize: 64,
      lineHeight: 1.1,
      fontWeight: 700,
      flexWrap: 'wrap',
    },
    planUserDescription: {
      fontSize: 12,
      color: primaryColor,

      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
    planUserTime: {
      fontSize: 12,
      color: lightGreyColor,
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
    planName: {
      color: whiteColor,
      fontSize: 16,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: 2,

      [theme.breakpoints.up('sm')]: {
        fontSize: 18,
      },
      '& > *:not(:last-child)': {
        marginRight: 10,
      },
    },
    planDescription: {
      fontSize: 12,

      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
    warningBox: {
      margin: '5px auto',
    },
    warnings: {
      marginBottom: 10,
    },
    planCapacity: {
      color: warningColor,
      fontSize: 14,
      fontWeight: 400,
    },
    editableField: {
      position: 'relative',
      paddingRight: 7,
      display: 'inline-block',

      [theme.breakpoints.up('md')]: {
        '&:hover $editableFieldIcon': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    editableFieldEmpty: {
      display: 'block',
      minHeight: 22,

      [theme.breakpoints.up('md')]: {
        '&:hover': {
          background: 'rgba(255,255,255, 0.02)',
        },
      },
    },
    editableFieldTouchable: {
      cursor: 'pointer',
    },
    editableFieldIcon: {
      fontSize: 14,
      color: textSecondaryColor,
      position: 'absolute',
      top: -2,
      left: '100%',
      ...transition,

      [theme.breakpoints.up('md')]: {
        opacity: 0,
        visibility: 'hidden',
      },
    },
    editForm: {
      display: 'flex',
      alignItems: 'center',
    },
    actionButton: {
      marginLeft: 5,
      padding: 2,
      color: textSecondaryColor,
    },
    actionButtonSave: {
      '&:hover': {
        color: primaryColor,
      },
    },
    actionButtonCancel: {
      '&:hover': {
        color: dangerColor,
      },
    },
    actionIcon: {
      fontSize: 16,
    },
    subscriptionButtonBlock: {
      marginTop: 24,
    },
    subscriptionInfoText: {
      marginTop: 15,
    },
    updateSubscriptionModal: {
      '& .subscription-button-block': {
        marginTop: 40,

        '& .subscription-button': {
          width: '100%',
        },
      },
    },
    updateSubscriptionContent: {
      fontSize: 20,
      marginBottom: 20,

      [theme.breakpoints.up('md')]: {
        fontSize: 24,
        marginBottom: 30,
      },
      '& img': {
        display: 'inline-block',
        marginBottom: 10,
      },
    },
    planNotificationBlock: {
      color: primaryColor,
      fontWeight: 700,
      marginTop: 3,
    },
    trialModalPaper: {
      maxWidth: '620px !important',
    },
  });
