interface ReactTableParams {
  s?: string;
  limit?: number;
  page?: number;
  sort?: any[];
  filter?: any;
}

export function toUriParams(params: any): string {
  let sfx = new URLSearchParams(params).toString();
  return sfx ? `?${sfx}` : '';
}

/**
 * Normalizes params received from React table before placing to request action
 * @param params
 */
export function prepareTableParams(params: ReactTableParams): ReactTableParams {
  if (params.sort && !params.sort.length) {
    delete params.sort;
  } else if (Array.isArray(params.sort)) {
    params.sort = params.sort.shift();
  }
  if (!params.page) {
    params.page = 1;
  }
  if (params.s && params.s === '{}') {
    delete params.s;
  }

  return params;
}
