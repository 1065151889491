import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  loading: boolean;
  children?: React.ReactNode;
  fullPage?: boolean;
  background?: boolean;
  notUnmount?: boolean;
  className?: string;
}

const Loader: React.FC<Props> = ({
  loading,
  children = '',
  fullPage = true,
  background = true,
  notUnmount = true,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <>
      {loading && (
        <div
          className={cx(classes.loaderWrapper, className, {
            [classes.loaderFullPageWrapper]: fullPage,
            [classes.loaderWrapperBackground]: background,
          })}
        >
          <CircularProgress className={classes.loader} size={60} />
        </div>
      )}
      {(notUnmount || !loading) && children}
    </>
  );
};

export default memo(Loader);
