import { createStyles, Theme } from '@material-ui/core/styles';

import { textPrimaryColor, boxShadow } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    cookieBlock: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(29,29,27, 0.8)',
      color: textPrimaryColor,
      padding: `10px 16px`,
      ...boxShadow,
      zIndex: 1400,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: '10%',
        paddingRight: '10%',
      },
      '& p': {
        fontSize: 14,
      },
    },
  });
