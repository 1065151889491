/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter, RouterState } from 'connected-react-router';

import { history } from 'utils/history';
import { InjectedReducersType } from 'utils/types/injector-typings';

import languageProviderReducer from 'app/components/common/LanguageProvider/reducer';
import auth from 'app/components/auth/reducers';
import playlist from 'app/components/melody/reducers';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    language: languageProviderReducer,
    [auth.key]: auth.reducer,
    [playlist.key]: playlist.reducer,
    ...injectedReducers,
    router: connectRouter(history) as Reducer<RouterState, AnyAction>,
  });
}
