import React from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';

import Logo from 'images/logo.svg';

import { LogoIcon } from './LogoIcon';
import styles from '../styles/authorization';

const useStyles = makeStyles(styles);

export interface Props {
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

const AuthorizationLayout: React.FC<Props> = ({ children, footer }) => {
  const classes = useStyles();

  return (
    <Container className={cx(classes.authorizationContainer, 'md-full-height-container')} maxWidth="xl">
      <div className={classes.authorizationContent}>
        <LogoIcon />
        {children}
      </div>
      {footer && <div className={classes.authorizationFooter}>{footer}</div>}
    </Container>
  );
};

export default AuthorizationLayout;
