import produce from 'immer';
import { handleActions, combineActions } from 'redux-actions';
import { loginAction, setUserAction, logoutUserAction, updateUserAction, clearAuthAction } from '../actions';

export const initialState = {
  authData: undefined,
  isLoading: false,
};

export default {
  key: 'auth',
  reducer: handleActions(
    {
      [combineActions(loginAction.REQUEST, updateUserAction.REQUEST)]: produce(draft => {
        draft.isLoading = true;
      }),
      [combineActions(loginAction.SUCCESS, updateUserAction.SUCCESS)]: produce((draft, action) => {
        draft.isLoading = false;
        draft.authData = action.payload;
      }),
      [updateUserAction.FAILURE]: produce(draft => {
        draft.isLoading = false;
      }),
      [setUserAction]: produce((draft, action) => {
        draft.authData = action.payload;
      }),
      [loginAction.FAILURE]: produce(draft => {
        draft.isLoading = false;
        draft.authData = undefined;
      }),
      [logoutUserAction.REQUEST]: produce(draft => {
        draft.isLoading = true;
      }),
      [combineActions(logoutUserAction.SUCCESS, logoutUserAction.FAILURE, clearAuthAction)]: produce(draft => {
        draft.authData = undefined;
        draft.isLoading = false;
      }),
    },
    initialState,
  ),
};
