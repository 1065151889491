import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    modalPaper: {},
    modalTitleBlock: {
      position: 'relative',
    },
    modalTitle: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.4rem',
      },
    },
    modalTitleContent: {},
    modalCloseButton: {
      position: 'absolute',
      right: 3,
      top: 3,
      padding: 8,
      '& svg': {
        fontSize: 20,
      },
    },
    modalContent: {
      fontSize: 16,
      wordBreak: 'break-word',

      '& .modal-button-block': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: 25,
      },
      '& .modal-button': {
        minWidth: 120,
        '&:last-child': {
          marginLeft: 'auto',
        },
      },
    },
    modalActions: {
      justifyContent: 'space-between',
    },
    modalActionsButton: {
      minWidth: 120,
    },
    modalConfirmation: {
      [theme.breakpoints.up('sm')]: {
        '& $modalTitle': {
          padding: '30px 40px 0',
        },
        '& $modalContent': {
          padding: '15px 40px 25px',
        },
        '& $modalActions': {
          padding: '0 40px 30px',
        },
      },
    },
  });
