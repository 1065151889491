import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import Link from '@material-ui/core/Link';

import useMenu from 'app/components/ui/Menu/hooks/useMenu';
import { MainMenu, FooterMenu } from 'configs/user.menu';
import useAuth from 'app/components/auth/hooks/useAuth';
import { useModal } from 'app/components/ui/Modal';
import ConfirmDialog from 'app/components/ui/ModalDialogs/ConfirmDialog';

import Logo from 'images/logo.svg';

import messages from '../messages';
import styles from '../styles/dashboard';

const useStyles = makeStyles(styles);

interface Props {
  readonly children?: React.ReactNode;
  readonly header?: 'absolute' | 'default';
  readonly className?: string;
  readonly disableMenu?: boolean;
  readonly hideMenuButton?: boolean;
}

const DashboardLayout: React.FC<Props> = ({
  children,
  header = 'default',
  className = '',
  disableMenu = false,
  hideMenuButton = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const respUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const { formatMessage } = useIntl();
  const { logout, isFullAccess } = useAuth();

  const mainMenu = useMenu(MainMenu).filter(item => item.title !== 'menuItemSavedMelodies' || isFullAccess);
  const footerMenu = useMenu(FooterMenu);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [showLogoutConfirm] = useModal({
    Modal: ConfirmDialog,
    onSubmit: logout,
    props: {
      title: formatMessage(messages.logoutDialogTitle),
      content: formatMessage(messages.logoutDialogText),
    },
  });

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const showConfirmLogoutDialog = () => {
    showLogoutConfirm();
  };

  const footerMenuActions = {
    logout: showConfirmLogoutDialog,
  };

  return (
    <Container className={cx(classes.rootContainer, className, 'md-full-height-container')} maxWidth={false}>
      <Drawer
        anchor="left"
        classes={{
          root: classes.sidebar,
          paper: classes.sidebarPaper,
        }}
        variant={respUpMd ? 'permanent' : 'temporary'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        elevation={0}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={classes.sidebarContent}>
          <div className={classes.sidebarLogoWrapper}>
            <div className={classes.sidebarLogo}>
              <img src={Logo} alt="logo" />
            </div>
            {!respUpMd && (
              <IconButton className={classes.sidebarCloseButton} color="inherit" onClick={handleDrawerToggle}>
                <CloseRoundedIcon />
              </IconButton>
            )}
          </div>
          <List className={classes.sidebarNav} component="nav">
            {mainMenu.map(
              ({ title, routePath, icon, show = true, disabled = false }) =>
                show && (
                  <ListItem
                    className={classes.sidebarNavItem}
                    button
                    key={title}
                    exact
                    component={NavLink}
                    to={routePath as string}
                    onClick={handleDrawerToggle}
                    disabled={disabled}
                  >
                    {icon && <ListItemIcon className={classes.sidebarNavIcon}>{icon}</ListItemIcon>}
                    <ListItemText
                      classes={{
                        root: classes.sidebarNavTextWrapper,
                        primary: classes.sidebarNavText,
                      }}
                      primary={messages[title] ? formatMessage(messages[title]) : title}
                    />
                  </ListItem>
                ),
            )}
          </List>
        </div>
        <div className={classes.sidebarFooter}>
          <div className={classes.sidebarSocialBlock}>
            <IconButton
              className={cx(classes.sidebarSocialButton, classes.sidebarSocialButtonInstagram)}
              href="https://instagram.com/themelodyapp/"
              target="_blank"
              onClick={handleDrawerToggle}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              className={cx(classes.sidebarSocialButton, classes.sidebarSocialButtonYouTube)}
              href="https://youtube.com/themelodyapp"
              target="_blank"
              onClick={handleDrawerToggle}
            >
              <YouTubeIcon />
            </IconButton>
          </div>
          <Divider className={classes.sidebarFooterDivider} variant="middle" />
          <div className={classes.sidebarFooterMenuBlock}>
            {footerMenu.map(({ title, routePath, extLink, action, show = true }) => {
              const itemProps = extLink
                ? {
                    href: extLink as string,
                    target: '_blank',
                    component: Link,
                  }
                : {
                    to: routePath as string,
                    component: NavLink,
                    onClick: handleDrawerToggle,
                  };
              return (
                show && (
                  <div className={classes.sidebarFooterMenuItem} key={title}>
                    {action ? (
                      <Button
                        className={classes.sidebarFooterMenuButton}
                        color="secondary"
                        onClick={footerMenuActions[action] ? footerMenuActions[action] : () => {}}
                      >
                        {messages[title] ? <FormattedMessage {...messages[title]} /> : title}
                      </Button>
                    ) : (
                      <Button className={classes.sidebarFooterMenuButton} color="secondary" {...itemProps}>
                        {messages[title] ? <FormattedMessage {...messages[title]} /> : title}
                      </Button>
                    )}
                  </div>
                )
              );
            })}
          </div>
        </div>
      </Drawer>

      {!respUpMd && (
        <div className={cx(classes.header, header ? classes[header] : '')}>
          <IconButton
            className={cx(classes.menuButton, { [classes.menuButtonHidden]: hideMenuButton })}
            color="inherit"
            onClick={handleDrawerToggle}
            disabled={disableMenu}
          >
            <SortRoundedIcon />
          </IconButton>
        </div>
      )}

      <main className={classes.mainContainer}>
        <div className={cx(classes.mainContent, classes.mainContentIndent)}>{children}</div>
      </main>
    </Container>
  );
};

export default DashboardLayout;
