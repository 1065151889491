import ApiClient from 'configs/axios';
import { toUriParams } from 'utils/params';
import mixpanel from 'mixpanel-browser';
/**
 * Get melodies data
 * @returns {AxiosPromise<any>}
 */
export function index(params) {
  return ApiClient.get(`/melodies${toUriParams(params)}`);
}

/**
 * Get melodies stats
 * @returns {AxiosPromise<any>}
 */
export function stats(params) {
  return ApiClient.get(`/stats${toUriParams(params)}`);
}

export function getCreators() {
  return ApiClient.get(`/user/creators`);
}

export function getCreator(id) {
  return ApiClient.get(`/user/creator/${id}`);
}

export function influencer() {
  return ApiClient.get(`/influencer`);
}

export function influencerAdmin() {
  return ApiClient.get(`/influencer/admin`);
}

export function searchUsers(search: string) {
  return ApiClient.get(`/user/search?search=${search}`);
}

export function patchInfluencer(data) {
  return ApiClient.patch(`/user/${data.id}/influencer`, {
    influencerUrl: data.influencerUrl,
  });
}

/**
 * Get bookmarks data
 * @returns {AxiosPromise<any>}
 */
export function listBookmarks(params) {
  if (!params.page) {
    params.page = 1;
  }
  return ApiClient.get(`/bookmarks${toUriParams(params)}`);
}

/**
 * Add melody to bookmarks data
 * @returns {AxiosPromise<any>}
 */
export function addBookmark(melodyId) {
  mixpanel.track('Melody Saved', { melodyId });
  return ApiClient.post(`/bookmarks`, { melodyId });
}

/**
 * Remove melodies from bookmarks
 * @returns {AxiosPromise<any>}
 */
export function removeBookmarks(data) {
  mixpanel.track('Remove Save', { data });
  return ApiClient.delete(`/bookmarks`, { data });
}

/**
 * Share melodies by email
 * @returns {AxiosPromise<any>}
 */
export function shareMelody(data) {
  mixpanel.track('Melody Share', { data });
  return ApiClient.post(`/downloads`, data);
}

/**
 * Generate link for melody download
 * @returns {AxiosPromise<any>}
 */
export function generateDownloadLink(ids: number[]) {
  return ApiClient.post(`/downloads/generate`, ids);
}

/**
 * Skip melody
 * @returns {AxiosPromise<any>}
 */
export function skipMelody(melodyId) {
  mixpanel.track('Melody Skip', { melodyId });
  return ApiClient.post(`/melodies/${melodyId}/skip`);
}

/**
 * Skip melody
 * @returns {AxiosPromise<any>}
 */
export function unSkipMelody(melodyId) {
  mixpanel.track('Melody Undo Skipped', { melodyId });
  return ApiClient.post(`/melodies/${melodyId}/skip-undo`);
}

/**
 * Mark listen melody
 * @returns {AxiosPromise<any>}
 */
export function listenMelody(melodyId) {
  mixpanel.track('Melody Listen', { melodyId });
  return ApiClient.post(`/melodies/${melodyId}/listen`);
}

/**
 * Bulk melodies edit
 * @returns {AxiosPromise<any>}
 */
export function bulkMelodiesEdit(melodyIds, data) {
  return ApiClient.put(`/melodies`, {
    melodyIds,
    data,
  });
}

/**
 * Bulk melodies edit
 * @returns {AxiosPromise<any>}
 */
export function updateMelody(melodyId, data) {
  return ApiClient.put(`/melodies/${melodyId}`, data);
}

/**
 * Delete melody
 * @returns {AxiosPromise<any>}
 */
export function deleteMelody(melodyId) {
  return ApiClient.delete(`/melodies/${melodyId}`);
}

/**
 * Get melodies stats
 * @returns {AxiosPromise<any>}
 */
export function melodiesStats(params: any) {
  return ApiClient.get(`/stats/melodies${toUriParams(params)}`);
}

/**
 * Get agregated stats
 * @returns {AxiosPromise<any>}
 */
export function aggregatedStats(params: any) {
  return ApiClient.get(`/stats/common${toUriParams(params)}`);
}

/**
 * Get unlimited count
 * @returns {AxiosPromise<any>}
 */
export function getUnlimitedCount() {
  return ApiClient.get('/subscriptions/unlimited-count');
}

/**
 * Drop skiplist
 * @returns {AxiosPromise<any>}
 */
export function dropSkipList() {
  return ApiClient.delete(`/melodies/skips/purge`);
}
