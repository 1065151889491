import axios from 'axios';
import { ACCESS_DENIED_PAGE } from './application';

const DEFAULT_ERROR = {
  message: 'Some error occurred...',
  statusCode: 500,
};

const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
  headers: {
    // @ts-ignore
    common: {
      'Content-Type': 'application/json',
    },
  },
});

// Request:
ApiClient.interceptors.request.use(config => {
  const authData = localStorage.getItem('auth');
  if (authData) {
    const userData = JSON.parse(authData);
    // @ts-ignore
    if (userData && userData.apiToken && !config.headers.common.Authorization) {
      // @ts-ignore
      config.headers.common.Authorization = `Bearer ${userData.apiToken}`;
    }
  }

  return config;
});

// Response:
ApiClient.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (response) {
      const { data, status } = response;
      let errorData: any = DEFAULT_ERROR;

      if (status >= 400) {
        errorData = data || DEFAULT_ERROR;
      }
      if (status === 401) {
        // Hard logout
        localStorage.removeItem('auth');
        window.location.pathname = ACCESS_DENIED_PAGE;
      }

      switch (status) {
        case 400:
        case 403:
        case 500:
          if (errorData.error && Array.isArray(errorData.error)) {
            errorData.message = errorData.error.join(', ');
          }
          console.error(errorData.message);
          break;
        default:
          break;
      }
      return Promise.reject(errorData);
    } else {
      console.error(error.message);
      return Promise.reject(error);
    }
  },
);

export default ApiClient;
