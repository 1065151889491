// Icon Left
import { ReactComponent as iconThumbsdown } from 'images/icons/swipe/icon-thumbsdown.svg';
import { ReactComponent as iconSkull } from 'images/icons/swipe/icon-skull.svg';

// Icon Right
import { ReactComponent as iconHeadphones } from 'images/icons/swipe/icon-headphones.svg';
import { ReactComponent as iconEyes } from 'images/icons/swipe/icon-eyes.svg';
import { ReactComponent as iconDiskette } from 'images/icons/swipe/icon-diskette.svg';

// Icon Up
import { ReactComponent as iconRaisingHands } from 'images/icons/swipe/icon-raising-hands.svg';
import { ReactComponent as iconBrainblown } from 'images/icons/swipe/icon-brainblown.svg';
import { ReactComponent as iconPrayingHands } from 'images/icons/swipe/icon-praying-hands.svg';
import { ReactComponent as iconFaceWthHeartEyes } from 'images/icons/swipe/icon-face-with-heart-eyes.svg';

export const IconMap = {
  left: [iconThumbsdown, iconSkull],
  right: [iconHeadphones, iconEyes, iconDiskette],
  up: [iconRaisingHands, iconBrainblown, iconPrayingHands, iconFaceWthHeartEyes],
};

/**
 * Get randon icon for icontype
 * @param iconName
 */
export function getRandomIcon(iconName: string): any {
  let resolvedIcon = null;

  if (IconMap[iconName] && iconName.length) {
    const IconArray = IconMap[iconName];
    resolvedIcon = IconArray[Math.round(Math.random() * (IconArray.length - 1))];
  }

  return resolvedIcon;
}
