import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { DashboardLayout } from 'app/components/layouts';

import { MakerDashboardPage } from './Dashboard/Loadable';
import { UploadsPage } from './Uploads/Loadable';
import { MyAccount } from './MyAccount/Loadable';

export default function MakerAppIndex() {
  return (
    <Switch>
      <Route exact path="/account">
        <DashboardLayout>
          <MyAccount />
        </DashboardLayout>
      </Route>
      <Route exact path="/uploads">
        <DashboardLayout>
          <UploadsPage />
        </DashboardLayout>
      </Route>
      <Route exact path="/">
        <DashboardLayout>
          <MakerDashboardPage />
        </DashboardLayout>
      </Route>
    </Switch>
  );
}
