import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { Page } from 'app/components/layouts';
import Loader from 'app/components/ui/loader/components/Loader';
import { DEFAULT_PAGE } from 'configs/application';
import { influencerAdmin, searchUsers, patchInfluencer } from 'app/components/melody/api';
import { useEffectOnce } from 'react-use';
import { Button, FilledInput, FormControl, InputAdornment, Typography } from '@material-ui/core';
import './styles/index.scss';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import UserIcon from '@material-ui/icons/Person';

export function InfluencerDashboard() {
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [loading] = useState<boolean>(false);
  const [searchedUsers, setSearchedUsers] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [newInfluencer, setNewInfluencer] = useState<any>(null);

  useEffectOnce(() => {
    influencerAdmin()
      .then(
        ({ data }) => {
          setData(data);
        },
        err => {},
      )
      .finally(() => {});
  });

  const saveNewInfluencer = async () => {
    await patchInfluencer(newInfluencer);
    influencerAdmin()
      .then(
        ({ data }) => {
          setData(data);
        },
        err => {},
      )
      .finally(() => {
        setNewInfluencer(null);
        setSearch('');
      });
  };

  const SearchUsers = (search: string) => {
    searchUsers(search)
      .then(
        ({ data }) => {
          setSearchedUsers(data);
        },
        err => {},
      )
      .finally(() => {});
  };

  return (
    <Loader loading={loading}>
      <Helmet>
        <title>Affiliate Admin Dashboard</title>
        <meta name="description" content="Influencer Dashboard" />
      </Helmet>
      <Page
        title="Influencer Admin Dashboard"
        className="influencerAdminDashboard"
      >
        <Grid container spacing={4}>
          <div className="add people">
            <Typography color="textSecondary" variant="h3" component="h3" gutterBottom>
              Add Affiliate
            </Typography>
            <Typography color="textPrimary" variant="body1">
              Add an affiliate to the Melody network
            </Typography>

            <div className="form-group searchGroup">
              <FormControl fullWidth={true} variant="filled">
                <FilledInput
                  id="input-with-icon-adornment"
                  fullWidth={true}
                  autoComplete="off"
                  onChange={e => {
                    setSearch(e.target.value);
                    if (e.target.value.length > 2) {
                      SearchUsers(e.target.value);
                    } else {
                      setSearchedUsers([]);
                    }
                  }}
                  value={search}
                  startAdornment={
                    <InputAdornment position="start">
                      <UserIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>

              <div className="search__results">
                {searchedUsers?.map((item: any) => (
                  <div
                    onClick={() => {
                      const newItem = { ...newInfluencer, id: item.id };
                      setNewInfluencer(newItem);
                      setSearchedUsers([]);
                      setSearch(item.name);
                    }}
                    className="row"
                  >
                    <div className="item">
                      <Typography component={'p'} className="invitee__name">
                        {item.name}
                      </Typography>

                      <Typography component={'p'} className="invitee__status">
                        {item.email}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {newInfluencer && (
              <div className="row code">
                <Typography component={'p'} className="invitee__name">
                  Affiliate Unique Code
                </Typography>

                <FormControl fullWidth={true} variant="filled">
                  <FilledInput
                    id="input-with-icon-adornment"
                    autoComplete="off"
                    onChange={e => {
                      const newItem = { ...newInfluencer, influencerUrl: e.target.value };
                      setNewInfluencer(newItem);
                    }}
                    value={newInfluencer?.influencerUrl}
                    startAdornment={
                      <InputAdornment position="start">
                        <AlternateEmail />
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    saveNewInfluencer();
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>

          <div className="people">
            <Typography color="textSecondary" variant="h3" component="h3" gutterBottom>
              Affiliates
            </Typography>
            <Typography color="textPrimary" variant="body1">
              A list of the affiliates that are in the Melody network
            </Typography>

            <div className="table">
              <div className="row header">
                <div className="item">Name</div>
                <div className="item">Email</div>
                <div className="item"># of Active Paying Invites</div>
                <div className="item">Code</div>
              </div>

              {data?.influencers?.map((item: any) => (
                <div className="row" key={item.id}>
                  <div className="item">{item.user.name}</div>
                  <div className="item">{item.user.email}</div>
                  <div className="item">{item.subscriptionsData.length}</div>
                  <div className="item">{item.user.influencerUrl}</div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Page>
    </Loader>
  );
}
