import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { history } from 'utils/history';

import { DEFAULT_PAGE } from 'configs/application';

export default OriginalComponent => {
  return props => {
    const { user } = useAuth();

    useEffect(() => {
      if (user) {
        // Redirect to access denied page or login page:
        history.push(DEFAULT_PAGE);
      }
    }, [user]);

    return !user ? <OriginalComponent {...props} /> : null;
  };
};
