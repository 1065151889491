import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import { Modal, ModalTitle, ModalContent, ModalProps } from 'app/components/ui/Modal';
import messages from './messages';

interface Props extends ModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSubmit: () => void;
  readonly title?: any;
  readonly content?: any;
  readonly buttonLabels?: any;
  readonly withCancel?: boolean;
}

const ConfirmDialog = ({ open, onClose, onSubmit, title, content, buttonLabels, withCancel = false }: Props) => {
  return (
    <Modal open={open} onBackdropClick={onClose}>
      <ModalTitle onClose={onClose}>{title}</ModalTitle>
      <ModalContent>
        {content}
        <div className="modal-button-block">
          {withCancel && (
            <Button className="modal-button" color="default" variant="contained" size="small" onClick={onClose}>
              {buttonLabels && buttonLabels.cancel ? (
                buttonLabels.cancel
              ) : (
                <FormattedMessage {...messages.defaultConfirmCancelButtonText} />
              )}
            </Button>
          )}
          <Button className="modal-button" color="primary" variant="contained" size="small" onClick={onSubmit}>
            {buttonLabels && buttonLabels.ok ? (
              buttonLabels.ok
            ) : (
              <FormattedMessage {...messages.defaultConfirmButtonText} />
            )}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDialog;
