import { createStyles } from '@material-ui/core/styles';

import { primaryColor, bgTransparentDark } from 'configs/variables';

export default () =>
  createStyles({
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      borderRadius: 'inherit',
      '&$loaderFullPageWrapper': {
        position: 'fixed',
      },
      '&$loaderWrapperBackground': {
        backgroundColor: bgTransparentDark,
      },
    },
    loaderWrapperBackground: {},
    loaderFullPageWrapper: {},
    loader: {
      color: primaryColor,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-30x',
      marginLeft: '-30px',
      zIndex: 100,
    },
  });
