/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Redirect, Switch } from 'react-router-dom';

import { LoginPage } from './containers/Auth/Login/Loadable';
import { MainPage } from './containers/Auth/Main/Loadable';
import { RegistrationPage } from './containers/Auth/Registration/Loadable';
import { InviteRegister } from './containers/Auth/Invite/Loadable';
import { RegistrationCreatorPage } from './containers/Auth/RegistrationCreator/Loadable';
import { PasswordRecoveryPage } from './containers/Auth/PasswordRecovery/Loadable';
import AdminApp from './containers/AdminApp';
import SearcherApp from './containers/SearcherApp';
import MakerApp from './containers/MakerApp';
import { NotFoundPage } from './containers/NotFoundPage/Loadable';

import useAcl from 'app/components/acl/hooks/useAcl';
import { ROLES } from 'app/components/acl/constants';
import { DashboardLayout } from './components/layouts';
import { LegalPage } from './containers/StaticPages';
import CookiesUsageWarning from 'app/components/common/CookiesUsageWarning';
import { useHistory } from 'react-router-dom';
import { mapNotifications, mapCallbacks, NotificationMessage, EVENT_KEY } from 'utils/notifications';
import { useSnackbar } from 'notistack';
import { useTrialModal } from './components/subscriptions/hooks/useTrialModal';

export function App() {
  const { checkRoles, checkRole, user } = useAcl();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    location: { search },
  } = history;

  const [showTrialModal] = useTrialModal();

  useEffect(() => {
    // Process forwarded events from URI query params
    const params = new URLSearchParams(search);

    if (params.has(EVENT_KEY)) {
      const msg: NotificationMessage | null = mapNotifications(search);
      mapCallbacks(search);

      if (params.get(EVENT_KEY) === 'paymentOk') {
        if (!!user.preferences) {
          history.push('/preferences');
        }
        showTrialModal();
      }

      if (msg) {
        enqueueSnackbar(msg.message, { variant: msg.type });
      }

      params.delete(EVENT_KEY);
      history.replace(
        params.toString() ? `${history.location.pathname}?${params.toString()}` : history.location.pathname,
      );
    }
  }, [search, history]);

  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="Melody">
        <meta name="description" content="Melody description" />
      </Helmet>

      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/register" component={RegistrationPage} />
        <Route path="/invite/:code" component={InviteRegister} />
        <Route exact path="/recovery" component={PasswordRecoveryPage} />
        <Route exact path="/accept-invite" component={RegistrationCreatorPage} />
        <Route exact path="/legal">
          <DashboardLayout disableMenu={checkRole(ROLES.Guest)}>
            <LegalPage />
          </DashboardLayout>
        </Route>
        <Route exact path="/404">
          <DashboardLayout header="absolute">
            <NotFoundPage />
          </DashboardLayout>
        </Route>
        {checkRole(ROLES.Guest) && (
          <>
            <Route exact path="/" component={MainPage} />
            <Redirect to="/" />
          </>
        )}
        {checkRoles([ROLES.Searcher, ROLES.Influencer]) && <SearcherApp />}
        {checkRoles([ROLES.Admin]) && <AdminApp />}
        {checkRoles([ROLES.Maker]) && <MakerApp />}
      </Switch>
      <CookiesUsageWarning />
    </>
  );
}
