/*
 *
 * LanguageProvider constants
 *
 */

enum ActionTypes {
  CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE',
}
export default ActionTypes;
