import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { Page } from 'app/components/layouts';
import Loader from 'app/components/ui/loader/components/Loader';
import { DEFAULT_PAGE } from 'configs/application';
import { influencer } from 'app/components/melody/api';
import { useEffectOnce } from 'react-use';
import { Typography } from '@material-ui/core';
import './styles/index.scss';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export function InfluencerDashboard() {
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [lastMonthSubscriptions, setLastMonthSubscriptions] = useState<any>([]);
  const [loading] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);

  useEffectOnce(() => {
    influencer()
      .then(
        ({ data }) => {
          setData(data);
        },
        err => {},
      )
      .finally(() => {});
  });

  return (
    <Loader loading={loading}>
      <Helmet>
        <title>Affiliate Dashboard</title>
        <meta name="description" content="Influencer Dashboard" />
      </Helmet>
      <Page title="Affiliate Dashboard" className="influencerDashboard">
        <Grid container spacing={4}>
          <div className="link">
            <Typography color="textSecondary" variant="h3" component="h3" gutterBottom>
              Your Invite Link
            </Typography>
            <Typography color="textPrimary" variant="body1">
              Share this link with your friends to earn money
            </Typography>

            <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    borderRadius: '5px 0px 0px 5px'
                  }}
                  value={`https://www.app.themelodyapp.com/invite/${data?.user?.influencerUrl}`}
                />
          

              <CopyToClipboard
                text={`https://www.app.themelodyapp.com/invite/${data?.user?.influencerUrl}`}
                onCopy={() => {
                  setCopied(true);

                  setTimeout(() => {
                    setCopied(false);
                  }, 4000);
                }}
              >
                <button style={{
                  height:'37px',
                  color: '#000',
                  fontWeight: 'bold',
                  padding: '0 20px',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: '#00FFBF'
                }} className="btn btn-primary">{copied ? 'Copied!' : 'Copy To Clipboard'}</button>
              </CopyToClipboard>
            </div>
          </div>

          <div className="people">
            <Typography color="textSecondary" variant="h3" component="h3" gutterBottom>
              Invited Users
            </Typography>
            <Typography color="textPrimary" variant="body1">
              A list of people that you have invited to Melody
            </Typography>

            <div className="table">
              <div className="row header">
                <div className="item">Name</div>
                <div className="item">Email</div>
                <div className="item">Plan</div>
              </div>
              {data?.influencers?.map((invite: any) => (
                <div className="row">
                  <div className="item">
                    <Typography component={'p'} className="invitee__name">
                      {invite.userId.name}
                    </Typography>
                  </div>
                  <div className="item">
                    <Typography component={'p'} className="invitee__status">
                      {invite.userId.email}
                    </Typography>
                  </div>
                  <div className="item">
                    <Typography component={'p'} className="invitee__status">
                      {invite.userId.subscription.plan.name}
                    </Typography>
                  </div>
                </div>
              ))}

              {data?.influencers?.length === 0 && (
                <div className="row">
                  <div className="item">
                    <Typography component={'p'} className="invitee__name">
                      No invites
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="people">
            <Typography color="textSecondary" variant="h3" component="h3" gutterBottom>
              Active Subscriptions
            </Typography>
            <Typography color="textPrimary" variant="body1">
              A list of people that you have invited to Melody and are paying customers
            </Typography>

            <div className="table">
              <div className="row header">
                <div className="item">Name</div>
                <div className="item">Email</div>
                <div className="item">Expires At</div>
              </div>
              {data?.subscriptions?.map((invite: any) => (
                <div className="row">
                  <div className="item">
                    <Typography component={'p'} className="invitee__name">
                      {invite.user.name}
                    </Typography>
                  </div>
                  <div className="item">
                    <Typography component={'p'} className="invitee__status">
                      {invite.user.email}
                    </Typography>
                  </div>
                  <div className="item">
                    <Typography component={'p'} className="invitee__status">
                      {moment(invite.expiredAt).format('MM/DD/YYYY')}
                    </Typography>
                  </div>
                </div>
              ))}

              {data?.subscriptions?.length === 0 && (
                <div className="row">
                  <div className="item">
                    <Typography component={'p'} className="invitee__name">
                      No invites
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Page>
    </Loader>
  );
}
