import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { DashboardLayout } from 'app/components/layouts';

import { AdminDashboardPage } from './Dashboard/Loadable';
import { UsersPage } from './Users/Loadable';
import { UploadsPage } from './Uploads/Loadable';
import { SyncPage } from './Sync';

import { UserFormPage } from './UserForm/Loadable';
import { StatisticsPage } from './Statistics/Loadable';
import { SubscriptionsPage } from './Subscriptions/Loadable';
import { InfluencerDashboard } from './Influencer';

export default function AdminAppIndex() {
  return (
    <Switch>
      <Route exact path="/statistics">
        <DashboardLayout>
          <StatisticsPage />
        </DashboardLayout>
      </Route>
      <Route exact path="/subscriptions">
        <DashboardLayout>
          <SubscriptionsPage />
        </DashboardLayout>
      </Route>
      <Route exact path="/users/:id">
        <DashboardLayout>
          <UserFormPage />
        </DashboardLayout>
      </Route>
      <Route exact path="/users">
        <DashboardLayout>
          <UsersPage />
        </DashboardLayout>
      </Route>
      <Route exact path="/affiliate">
        <DashboardLayout>
          <InfluencerDashboard />
        </DashboardLayout>
      </Route>
      <Route exact path="/uploads">
        <DashboardLayout>
          <UploadsPage />
        </DashboardLayout>
      </Route>
      <Route exact path="/sync">
        <DashboardLayout>
          <SyncPage />
        </DashboardLayout>
      </Route>
      <Route exact path="/">
        <DashboardLayout>
          <AdminDashboardPage />
        </DashboardLayout>
      </Route>
    </Switch>
  );
}
