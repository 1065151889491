import { createStyles } from '@material-ui/core/styles';

export default () =>
  createStyles({
    iconActionProviderWrapper: {},
    iconAction: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: 60,
      opacity: 0.5,
      zIndex: 10,
    },
  });
