import { ROLES } from 'app/components/acl/constants';
import { UserSubscriptionInterface } from './subscription.type';
import { plainToClass, classToPlain, serialize } from 'class-transformer';
import { LOW_BALANCE_FLAG_VARNAME } from 'configs/subscriptions';

const AUTH_STORAGE_KEY = 'auth';

export interface UserPreferencesInterface {
  readonly tags?: number[];
  readonly creators?: number[];
}

export interface UserInterface {
  readonly id?: number;
  readonly about?: string | null;
  readonly apiToken?: string;
  readonly email: string;
  readonly emailVerified: boolean;
  readonly name: string;
  readonly preferences?: UserPreferencesInterface;
  readonly role: ROLES;
  readonly subscription?: UserSubscriptionInterface | null;
  readonly isActive: boolean;
}

export default class User implements UserInterface {
  id?: number;
  name: string = '';
  email: string = '';
  about?: string | null = null;
  apiToken: string = '';
  role: ROLES = ROLES.Searcher;
  preferences: UserPreferencesInterface = {};
  subscription: UserSubscriptionInterface | null = null;
  emailVerified: boolean = false;
  isActive;
  providers?: string[] = [];
  isTrialAllowed?: boolean = false;
  metadata?: any = {};

  constructor(userData?: UserInterface) {
    this.bindWithData(userData);
  }

  bindWithData(userData: any): User {
    if (userData) {
      //Init with data from userData:
      Object.keys(userData).forEach(key => {
        if (this.hasOwnProperty(key)) {
          this[key] = userData[key];
        }
      });
    }

    return this;
  }

  static populate(): User | null {
    const authData = localStorage.getItem(AUTH_STORAGE_KEY);
    return authData ? User.createFrom(JSON.parse(authData)) : null;
  }

  static createFrom(userData: any): User {
    return plainToClass(User, userData as UserInterface);
  }

  store(): User {
    localStorage.setItem(AUTH_STORAGE_KEY, serialize(this));
    return this;
  }

  toPlain(): any {
    return classToPlain(this);
  }

  dispose() {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    localStorage.removeItem('soundWarn');
    localStorage.removeItem(LOW_BALANCE_FLAG_VARNAME);
  }
}
