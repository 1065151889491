import produce from 'immer';
import { handleActions, combineActions } from 'redux-actions';
import { playlistInitialLoad, playlistLoad, shiftPlaylist, unshiftPlaylist } from '../actions';

export const initialState = {
  loading: false,
  dump: [],
  data: [],
  buffer: [],
  current: 0,
  meta: {},
  bufferVacant: false,
  endReached: false,
};

export default {
  key: 'playlist',
  reducer: handleActions(
    {
      [combineActions(playlistInitialLoad.REQUEST)]: produce(draft => {
        draft.loading = true;
        draft.current = 0;
        draft.endReached = false;
      }),
      [combineActions(playlistInitialLoad.SUCCESS)]: produce((draft, action) => {
        draft.loading = false;
        draft.data = action.payload.data;
        draft.meta = action.payload.meta;
        draft.buffer = [];
        draft.dump = action.payload.data;
      }),
      [playlistInitialLoad.FAILURE]: produce(draft => {
        draft.loading = false;
      }),
      [shiftPlaylist]: produce(draft => {
        if (draft.current < draft.data.length - 1) {
          draft.current += 1;
        } else if (draft.buffer.length) {
          draft.data = draft.buffer;
          draft.buffer = [];
          draft.current = 0;
          draft.bufferVacant = true;
        }
      }),
      [unshiftPlaylist]: produce(draft => {
        if (draft.current > 0) {
          draft.current -= 1;
        }
      }),
      [combineActions(playlistLoad.SUCCESS)]: produce((draft, action) => {
        draft.buffer = action.payload.data;
        draft.dump = action.payload.data;
        draft.meta = action.payload.meta;
        draft.bufferVacant = false;
        draft.endReached = action.payload.data.length < 1;
      }),
    },
    initialState,
  ),
};
