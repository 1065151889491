import useAuth from 'app/components/auth/hooks/useAuth';
import { CheckRolesOption, ROLES } from '../constants';

export default function useAcl() {
  const { user } = useAuth();
  const checkRole = (roleName: ROLES): boolean => {
    if (roleName === ROLES.Guest) {
      return !user;
    } else {
      return !!user && user.role === roleName;
    }
  };

  const checkRoles = (roleNames: ROLES[], checkOption: CheckRolesOption = CheckRolesOption.Any): boolean => {
    let checkResult = false;
    const factRole = !!user ? user.role : ROLES.Guest;
    switch (checkOption) {
      case 1: //@TODO Implement this if user can have few roles
      case 0:
      default:
        checkResult = roleNames.includes(factRole);
    }

    return checkResult;
  };

  return {
    user,
    checkRole,
    checkRoles,
  };
}
