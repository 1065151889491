import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector, userIsLoadingSelector } from '../selectors';
import { InjectSagaParams } from 'utils/types/injector-typings';

import { logoutUserWatcherSaga } from '../sagas';
import { logoutUserAction, setUserAction } from '../actions';
import { useInjectSaga } from 'utils/redux-injectors';
import User from 'types/user.type';
import { LOW_BALANCE_FLAG_VARNAME } from 'configs/subscriptions';
import { PLAN, PROVIDER } from 'app/components/subscriptions/constants';
import mixpanel from 'mixpanel-browser';
import LogRocket from 'logrocket';

export default function useAuth() {
  const dispatch = useDispatch();
  const currentUser = useSelector(userDataSelector);
  useInjectSaga(logoutUserWatcherSaga as InjectSagaParams);

  const checkForBalanceUpdates = (prevUser: User, nextUser: User) => {
    if (prevUser && prevUser.subscription && nextUser && nextUser.subscription) {
      if (nextUser.subscription.credits > prevUser.subscription.credits) {
        // Drop low balance alert flag:
        localStorage.removeItem(LOW_BALANCE_FLAG_VARNAME);
      }
    }
  };

  const logout = useCallback(() => {
    dispatch(logoutUserAction.request());
  }, [dispatch]);

  const update = (user: User) => {
    checkForBalanceUpdates(currentUser, user);
    user.store();
    dispatch(setUserAction(user.toPlain()));
  };

  useEffect(() => {
    if (currentUser && !currentUser.isActive && currentUser.subscription?.plan.key === 'free') {
      logout();
    }
  }, [currentUser]);

  const user = User.createFrom(useSelector(userDataSelector));

  const plan = user?.subscription?.plan?.key || PLAN.FREE;
  const provider = user?.subscription?.provider || PROVIDER.NONE;

  if (user && user.id) {
    mixpanel.identify(user.id, { email: user.email });
    LogRocket.identify(user.email, {
      name: user.name,
      email: user.email,
      subscriptionType: user?.subscription?.plan?.key || PLAN.FREE,
    });
  }

  const isFullAccess = plan !== PLAN.FREE;

  const isAnotherProvider = Boolean(
    plan !== PLAN.FREE && plan !== PLAN.UNLIMITED && provider && provider !== PROVIDER.STRIPE,
  );

  return {
    user,
    logout,
    update,
    provider,
    plan,
    isFullAccess,
    isAnotherProvider,
    loading: useSelector(userIsLoadingSelector),
  };
}
