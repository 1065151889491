export enum ROLES {
  Guest = 'guest',
  Admin = 'admin',
  Maker = 'maker',
  Searcher = 'searcher',
  Influencer = 'influencer',
}

export enum CheckRolesOption {
  Any,
  All,
}
