import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Dialog, DialogProps } from '@material-ui/core';

import Transition from './Transition';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends DialogProps {}

const Modal = ({ maxWidth = 'sm', scroll = 'body', fullWidth = true, className = '', ...props }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: cx(classes.modalPaper, className) }}
      maxWidth={maxWidth}
      scroll={scroll}
      fullWidth={fullWidth}
      TransitionComponent={Transition}
      {...props}
    />
  );
};

export default memo(Modal);
