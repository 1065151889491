import { defineMessages } from 'react-intl';

export const scope = 'dialogs';

export default defineMessages({
  defaultConfirmButtonText: {
    id: `${scope}.ok.confirm.button`,
    defaultMessage: 'Yes',
  },
  defaultConfirmCancelButtonText: {
    id: `${scope}.cancel.confirm.button`,
    defaultMessage: 'Cancel',
  },
});
