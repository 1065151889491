import { createStyles, Theme } from '@material-ui/core/styles';

import { primaryColor, dangerColor, textSecondaryColor, transition } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    editableField: {
      cursor: 'pointer',
      position: 'relative',
      paddingRight: 7,
      display: 'inline-block',

      [theme.breakpoints.up('md')]: {
        '&:hover $editableFieldIcon': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    editableFieldEmpty: {
      display: 'block',
      minHeight: 22,

      [theme.breakpoints.up('md')]: {
        '&:hover': {
          background: 'rgba(255,255,255, 0.02)',
        },
      },
    },
    editableFieldTouchable: {
      cursor: 'pointer',
    },
    editableFieldIcon: {
      fontSize: 14,
      color: textSecondaryColor,
      position: 'absolute',
      top: -2,
      left: '100%',
      ...transition,

      [theme.breakpoints.up('md')]: {
        opacity: 0,
        visibility: 'hidden',
      },
    },
    editForm: {
      display: 'flex',
      alignItems: 'center',
    },
    actionButton: {
      marginLeft: 5,
      padding: 2,
      color: textSecondaryColor,
    },
    actionButtonSave: {
      '&:hover': {
        color: primaryColor,
      },
    },
    actionButtonCancel: {
      '&:hover': {
        color: dangerColor,
      },
    },
    actionIcon: {
      fontSize: 16,
    },
  });
