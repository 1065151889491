import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import { Page } from 'app/components/layouts';
import { syncData } from 'app/components/subscriptions/api';
import { CSVLink } from 'react-csv';

export function SyncPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    syncData()
      .then(
        ({ data }) => {
          const header = ['id', 'email', 'name', 'subscription', 'registration'];

          const csvData = data.map(item => {
            const createdAt = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');

            if(item.subscription?.plan?.key === 'premium') {
              const OlderThanAWeek = moment().subtract(1, 'week').isAfter(item.subscription?.createdAt);
          
              // They are out of trial
              if(OlderThanAWeek) {
                return [item.id, item.email, item.name, 'subscribed', createdAt];
              } else {
                // They are still in trial
                return [item.id, item.email, item.name, 'trial', createdAt];
              }
            }

            return [item.id, item.email, item.name, item.subscription?.plan?.key || 'free', createdAt];
          });

          setCsvData([header, ...csvData]);
        },
        err => {
          enqueueSnackbar('Unable to load user data', { variant: 'error' });
        },
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Page title="Exports">
      <Helmet>
        <title>Export</title>
        <meta name="description" content="Exports" />
      </Helmet>

      <Box>
        <Typography variant="body1">Get a CSV file of all users and their subscription status.</Typography>
        {!loading && (
          <CSVLink
            style={{
              textDecoration: 'none',
              color: 'white',
              backgroundColor: '#3f51b5',
              padding: '10px',
              borderRadius: '5px',
              margin: '10px 0',
              marginTop: '20px',
              display: 'inline-block',
            }}
            data={csvData}
          >
            Download CSV
          </CSVLink>
        )}

        {loading && <Typography variant="body1">Loading...</Typography>}
      </Box>
    </Page>
  );
}
