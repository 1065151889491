import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import styles from './styles';

const useStyles = makeStyles(styles);
const COOKIES_USG_KEY = 'cookiesUsageAccepted';

export default function CookiesUsageWarning() {
  const classes = useStyles();
  const [accepted, setAccepted] = useState<boolean>(!!localStorage.getItem(COOKIES_USG_KEY));

  const handleAccept = () => {
    setAccepted(true);
    localStorage.setItem(COOKIES_USG_KEY, JSON.stringify(true));
  };

  return accepted ? null : (
    <div className={classes.cookieBlock}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm>
          <Typography>
            We use cookies on our website to support its correct operation. You are free to manage this anytime via your
            browser settings. By clicking the 'Accept' option you agree to our&nbsp;
            <Link href="https://themelodyapp.com/cookie" target="_blank" underline="always">
              Cookies Policy
            </Link>
            . Please note that you won’t be able to register/login via Google if the cookies are off.
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button color="primary" variant="contained" size="small" fullWidth onClick={handleAccept}>
            Accept
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
