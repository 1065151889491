import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from '../styles/page';

const useStyles = makeStyles(styles);

export interface Props {
  readonly children?: React.ReactNode;
  readonly title?: string | React.ReactNode;
  readonly titleVariant?: 'h1' | 'h2' | 'h3';
  readonly headerAction?: React.ReactNode;
  readonly onClose?: () => void;
  readonly bordered?: 'outside' | boolean;
  readonly closePosition?: 'inner' | 'outer';
  readonly read?: boolean;
  readonly className?: string;
}

const Page: React.FC<Props> = ({
  children = '',
  title,
  titleVariant = 'h2',
  headerAction,
  onClose,
  bordered = false,
  closePosition = 'outer',
  read = false,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.pageBlock, read ? classes.pageBlockRead : '', className)}>
      {onClose && (
        <IconButton
          className={cx(classes.pageCloseButton, closePosition ? classes[closePosition] : '', 'page-close-button')}
          color="inherit"
          onClick={onClose}
        >
          <CloseRoundedIcon />
        </IconButton>
      )}
      {title && (
        <div className={classes.pageHeader}>
          <Typography className={classes.pageHeaderTitle} variant={titleVariant}>
            {title}
          </Typography>
          {headerAction && <div className={classes.pageHeaderAction}>{headerAction}</div>}
        </div>
      )}
      {bordered && (
        <Divider
          className={classes.pageHeaderBorder}
          variant={bordered === 'outside' ? 'middle' : 'fullWidth'}
          component="div"
        />
      )}
      {children}
    </div>
  );
};

export default memo(Page);
