import { createStyles, Theme } from '@material-ui/core/styles';

import { globalIndentXs, buttonMinWidth, bgTransparentDark, drawerWidth } from 'configs/variables';

const bottomNavStyle = (theme: Theme) =>
  createStyles({
    bottomStickyButtonIndentWrapper: {
      paddingBottom: 65,
    },
    bottomStickyButtonBlock: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: 'center',
      padding: `0 ${globalIndentXs}px 10px`,
      backgroundColor: bgTransparentDark,

      [theme.breakpoints.up('md')]: {
        left: drawerWidth,
      },
    },
    bottomStickyButton: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        minWidth: buttonMinWidth,
      },
    },
  });

export default bottomNavStyle;
