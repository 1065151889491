import { createStyles, Theme } from '@material-ui/core';

import { layoutHorizontalIndent, textSecondaryColor } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    authorizationContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 40,
      paddingBottom: 40,
      ...layoutHorizontalIndent,
    },
    authorizationContent: {
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        width: 400,
      },
    },
    authorizationLogo: {
      textAlign: 'center',
      marginBottom: 50,
      [theme.breakpoints.up('sm')]: {
        marginBottom: 80,
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: 110,
      },
    },
    authorizationLogoImage: {
      width: 153,
      height: 36,
      [theme.breakpoints.up('sm')]: {
        width: 196,
        height: 46,
      },
    },
    authorizationFooter: {
      fontSize: 12,
      marginTop: 50,
      textAlign: 'center',

      '&, & a': {
        color: textSecondaryColor,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 60,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 80,
      },
    },
  });
