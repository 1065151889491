import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DashboardLayout } from 'app/components/layouts';

import { MyAccount } from './MyAccount/Loadable';
import { PreferencesPage } from './Preferences/Loadable';
import { HomePage } from './Home/Loadable';
import { SavedMelodiesPage } from './SavedMelodies/Loadable';
import { NotFoundPage } from '../NotFoundPage/Loadable';
import useAuth from 'app/components/auth/hooks/useAuth';
import { reloadUser } from 'app/components/auth/api';
import User from 'types/user.type';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { InfluencerDashboard } from './Influencer';
import { CreatorsPage } from './Creators';
import { CreatorProfile } from './Creators/profile';


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const REFRESH_INTERVAL_MS = 5 * 60 * 1000; // 5 min

export default function SearcherAppIndex() {
  const [refreshTimer, setRefreshTimer] = useState<number | null>(null);
  const { update } = useAuth();

  useEffect(() => {
    if (!refreshTimer) {
      setRefreshTimer(
        // @ts-ignore
        setInterval(() => {
          // Reload(refresh) user data from API:
          reloadUser()
            .then(({ data }) => {
              // Update auth data:
              update(User.createFrom(data));
            })
            .catch(err => {
              console.error('Unable to reload user data: ', err);
            });
        }, REFRESH_INTERVAL_MS),
      );
    }

    return () => {
      if (refreshTimer) {
        clearInterval(refreshTimer);
      }
    };
  }, []);

  return (
    // @ts-ignore
    <Elements stripe={stripePromise}>
      <Switch>
        <Route exact path="/account">
          <DashboardLayout>
            <MyAccount />
          </DashboardLayout>
        </Route>
        <Route exact path="/affiliate">
        <DashboardLayout>
          <InfluencerDashboard />
        </DashboardLayout>
      </Route>
        <Route exact path="/preferences">
          <DashboardLayout hideMenuButton>
            <PreferencesPage />
          </DashboardLayout>
        </Route>
        <Route exact path="/creators/:id">
          <DashboardLayout>
            <CreatorProfile />
          </DashboardLayout>
        </Route>
        <Route exact path="/creators">
          <DashboardLayout>
            <CreatorsPage />
          </DashboardLayout>
        </Route>
        <Route exact path="/bookmarks">
          <DashboardLayout>
            <SavedMelodiesPage />
          </DashboardLayout>
        </Route>
        <Route exact path="/">
          <DashboardLayout className="overflow-hidden" header="absolute">
            <HomePage />
          </DashboardLayout>
        </Route>
        <Route>
          <DashboardLayout header="absolute">
            <NotFoundPage />
          </DashboardLayout>
        </Route>
      </Switch>
    </Elements>
  );
}
