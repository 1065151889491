import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import {
  fontFamily,
  bgBody,
  bgPaper,
  bgPaperMenu,
  textPrimaryColor,
  textSecondaryColor,
  textBlackColor,
  primaryColor,
  primaryColorDark,
  whiteColor,
  purpleColor,
  secondaryColor,
  secondaryColorDark,
  warningColor,
  warningColorDark,
  dangerColor,
  boxShadow,
  borderPrimaryColor,
  borderFormColor,
  borderRadiusEllipse,
  layoutHorizontalIndentRemove,
  disableColor,
  disableTextColor,
  tableBorderColor,
  tableBackground,
  tableMaxIndent,
  buttonMinWidth,
  customBrowserScrollBar,
} from 'configs/variables';

const breakpoints = createBreakpoints({});

export default createTheme({
  breakpoints,
  palette: {
    type: 'dark',
    primary: {
      main: primaryColor,
      dark: primaryColorDark,
      contrastText: whiteColor,
    },
    secondary: {
      main: secondaryColor,
      dark: secondaryColorDark,
    },
    error: {
      main: dangerColor,
    },
    warning: {
      main: warningColor,
    },
    background: {
      default: bgBody,
      paper: bgPaper,
    },
    text: {
      primary: textPrimaryColor,
      secondary: textSecondaryColor,
    },
  },
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontSize: '2.1875rem', // 35
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.75rem', // 28
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem', // 24
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.25rem', // 20
      fontWeight: 700,
    },
    h5: {
      fontSize: 18,
      fontWeight: 700,
    },
    button: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 1.4,
      textTransform: 'none',
    },
    body2: {
      fontSize: 18,
      lineHeight: 1.4,
    },
    caption: {
      fontSize: 14,
      lineHeight: 1.4,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '15px 20px',
        borderRadius: borderRadiusEllipse,
        ...boxShadow,

        '&.cta-button': {
          width: buttonMinWidth,
          maxWidth: '100%',
        },
      },
      contained: {
        '&.button-warning:not($disabled)': {
          color: textPrimaryColor,
          backgroundColor: warningColor,
          '&:hover': {
            color: textPrimaryColor,
            backgroundColor: warningColorDark,
          },
        },
        '&$disabled, &$disabled:hover': {
          color: disableTextColor,
          backgroundColor: disableColor,
        },
      },
      containedSizeSmall: {
        padding: '7px 20px',
        fontSize: '1rem',
      },
      containedPrimary: {
        color: textBlackColor,
        backgroundColor: primaryColor,
        '&:hover': {
          backgroundColor: primaryColorDark,
        },
      },
      outlined: {
        padding: '14px 20px',
      },
      outlinedSizeSmall: {
        padding: '6px 20px',
        fontSize: '1rem',
      },
      text: {
        '&, &:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        '&:hover': {
          opacity: 0.85,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 6,
        fontSize: 22,

        '& svg': {
          fontSize: 'inherit',
        },
        '&.delete-button': {
          color: dangerColor,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: textPrimaryColor,
        padding: 8,
        '&$disabled': {
          '& svg': {
            opacity: 0.5,
          },
        },
      },
    },
    MuiTextField: {
      root: {},
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 16,
      },
    },
    MuiFormLabel: {
      root: {
        color: textPrimaryColor,
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderBottom: `2px solid ${borderFormColor}`,
        },
        '&$disabled::before': {
          borderBottomStyle: 'solid',
          borderBottomColor: 'rgba(255, 255, 255, 0.5)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline, &$focused $notchedOutline': {
          borderWidth: 1,
          borderColor: whiteColor,
        },
        '.purple > &, &.purple': {
          '&, & > svg': {
            color: purpleColor,
          },
          '& $notchedOutline, &$focused $notchedOutline': {
            borderColor: purpleColor,
          },
        },
        '&:focus': {
          outline: 0,
        },
      },
      notchedOutline: {
        '&:focus': {
          outline: 0,
        },
      },
      input: {
        padding: '13px 23px',
      },
    },
    MuiSelect: {
      outlined: {
        '&, & ~ fieldset': {
          borderRadius: borderRadiusEllipse,
        },
      },
      select: {
        fontWeight: 700,
        textAlign: 'center',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: bgPaperMenu,
      },
      // @ts-ignore
      list: {
        overflow: 'auto',
        listStyle: 'none',
        maxHeight: '40vh',
        ...customBrowserScrollBar,
      },
    },
    // @ts-ignore
    MuiAutocomplete: {
      inputRoot: {
        borderRadius: borderRadiusEllipse,
      },
      paper: {
        backgroundColor: bgPaperMenu,
      },
      listbox: {
        ...customBrowserScrollBar,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 10,
        marginTop: '0.4em',
        marginBottom: 'calc(-1em - 0.4em)',
        lineHeight: 1,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderPrimaryColor,
      },
      middle: {
        ...layoutHorizontalIndentRemove,
      },
    },
    MuiDialog: {
      paper: {
        ...boxShadow,
        [breakpoints.down('sm')]: {
          margin: 15,
        },
        [breakpoints.down('xs')]: {
          '&$paperScrollBody': {
            maxWidth: 'calc(100% - 30px) !important',
          },
        },
      },
      paperWidthXs: {},
      paperWidthSm: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('sm')]: {
            maxWidth: 485,
          },
        },
      },
      paperWidthMd: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('sm')]: {
            maxWidth: 585,
          },
        },
      },
      paperWidthLg: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('md')]: {
            maxWidth: 800,
          },
        },
      },
      paperScrollBody: {},
      paperFullWidth: {
        width: 'calc(100% - 30px)',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '15px 20px 0',
        [breakpoints.up('sm')]: {
          padding: '20px 30px 0',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '15px 20px',
        [breakpoints.up('sm')]: {
          padding: '20px 30px',
        },
        '&:last-child': {
          paddingBottom: 15,
          [breakpoints.up('sm')]: {
            paddingBottom: 20,
          },
        },
        '&:first-child': {
          paddingTop: 15,
          [breakpoints.up('sm')]: {
            paddingTop: 20,
          },
        },
      },
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'middle',
      },
    },
    MuiTabs: {
      root: {
        color: textSecondaryColor,
      },
      indicator: {
        height: 5,
      },
    },
    MuiTab: {
      root: {
        fontWeight: 700,
        fontSize: 22,
        padding: 5,
        marginLeft: 15,
        marginRight: 15,
        minWidth: 0,
        [breakpoints.up('sm')]: {
          minWidth: 0,
        },
        '&:hover, &$selected, &$selected:hover': {
          color: textPrimaryColor,
        },
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
      textColorInherit: {
        opacity: 1,
      },
      fullWidth: {
        flexBasis: 'auto',
      },
    },
    MuiChip: {
      root: {
        borderRadius: borderRadiusEllipse,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      sizeSmall: {
        height: 25,
      },
      colorPrimary: {
        color: textBlackColor,
      },
      deleteIconColorPrimary: {
        color: textBlackColor,
      },
      deleteIcon: {
        '&, &:hover, &:active': {
          color: 'inherit !important',
        },
        '&:hover, &:active': {
          opacity: 0.8,
        },
      },
    },
    MuiLink: {
      underlineAlways: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiTable: {
      root: {},
    },
    MuiTableRow: {
      root: {
        borderBottom: `1px solid ${tableBorderColor}`,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
      head: {
        backgroundColor: tableBackground,
        '&, &:last-child': {
          borderBottom: `1px solid ${tableBorderColor}`,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 10,
        borderBottom: 'none',
        wordBreak: 'break-word',

        '&:first-child': {
          paddingLeft: tableMaxIndent,
        },
        '&:last-child': {
          paddingRight: tableMaxIndent,
        },
        '&.text-right': {
          textAlign: 'right',
        },
      },
      head: {
        position: 'relative',
        fontSize: 18,
        fontWeight: 400,
      },
      body: {
        fontSize: 16,
      },
      stickyHeader: {
        backgroundColor: tableBackground,
      },
      footer: {
        justifyContent: 'flex-end',
      },
    },
    MuiTableFooter: {
      root: {
        backgroundColor: bgPaper,
      },
    },
    MuiTablePagination: {
      root: {
        width: '100%',
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        padding: `7px ${tableMaxIndent}px`,
        minHeight: 0,
        paddingRight: tableMaxIndent,
      },
      caption: {
        fontSize: 12,

        [breakpoints.up('md')]: {
          fontSize: 14,
        },
      },
      selectRoot: {},
      select: {
        paddingTop: 6,
        paddingBottom: 6,
      },
    },
    MuiSnackbar: {
      anchorOriginBottomRight: {
        [breakpoints.up('sm')]: {
          right: 15,
          bottom: 15,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        '&.snackbar-provider.success': {
          backgroundColor: primaryColor,
          color: textBlackColor,

          '& button': {
            color: textBlackColor,
            '&:hover': {
              opacity: 0.7,
            },
          },
        },
      },
    },
  },
});
