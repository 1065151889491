import { createFormAction } from 'redux-form-saga';
import { createAction } from 'redux-actions';

export const playlistInitialLoad = createFormAction('PLAYLIST_INIT_LOAD');
export const playlistLoad = createFormAction('PLAYLIST_LOAD');
export const shiftPlaylist = createAction('PLAYLIST_SHIFT');
export const unshiftPlaylist = createAction('PLAYLIST_UNSHIFT');
export const playlistSkip = createAction('PLAYLIST_SKIP');
export const playlistUnSkip = createAction('PLAYLIST_UNSKIP');
export const playlistSave = createAction('PLAYLIST_SAVE');
