import React from 'react';

import FiberSmartRecordOutlinedIcon from '@material-ui/icons/FiberSmartRecordOutlined';
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import UserIcon from '@material-ui/icons/AccountBox';
import Grade from '@material-ui/icons/Grade';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import MusicNoteOutlinedIcon from '@material-ui/icons/MusicNoteOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SyncIcon from '@material-ui/icons/CloudDownload';

import { ROLES } from 'app/components/acl/constants';
import { MenuItemType } from 'app/components/ui/Menu/types/menuitem.type';

export const MainMenu: MenuItemType[] = [
  {
    title: 'menuItemHome',
    routePath: '/',
    roles: [ROLES.Searcher, ROLES.Influencer],
    icon: <FiberSmartRecordOutlinedIcon />,
  },
  {
    title: 'menuItemDashboard',
    routePath: '/',
    roles: [ROLES.Admin, ROLES.Maker],
    icon: <TrendingUpIcon />,
  },
  {
    title: 'menuItemMyAccount',
    routePath: '/account',
    roles: [ROLES.Searcher, ROLES.Maker, ROLES.Influencer],
    icon: <UserIcon />,
  },
  {
    title: 'menuItemInfluencer',
    routePath: '/affiliate',
    roles: [ROLES.Influencer],
    icon: <Grade />,
  },
  {
    title: 'menuItemCreators',
    routePath: '/creators',
    roles: [ROLES.Searcher, ROLES.Influencer],
    icon: <MusicNoteOutlinedIcon />,
  },
  {
    title: 'menuItemInfluencer',
    routePath: '/affiliate',
    roles: [ROLES.Admin],
    icon: <Grade />,
  },
  {
    title: 'menuItemSubscriptions',
    routePath: '/subscriptions',
    roles: [ROLES.Admin],
    icon: <AssignmentTurnedInIcon />,
  },
  {
    title: 'menuItemUsers',
    routePath: '/users',
    roles: [ROLES.Admin],
    icon: <PeopleOutlinedIcon />,
  },
  {
    title: 'menuItemMyMelodies',
    routePath: '/uploads',
    roles: [ROLES.Admin, ROLES.Maker],
    icon: <MusicNoteOutlinedIcon />,
  },
  {
    title: 'menuItemStatistics',
    routePath: '/statistics',
    roles: [ROLES.Admin],
    icon: <EqualizerRoundedIcon />,
  },
  {
    title: 'Exports',
    routePath: '/sync',
    roles: [ROLES.Admin],
    icon: <SyncIcon />,
  },
];

export const FooterMenu: MenuItemType[] = [
  {
    title: 'FAQ',
    extLink: 'https://themelodyapp.com/faq',
  },
  {
    title: 'contactus',
    extLink: 'https://themelodyapp.com/contact-us',
  },
  {
    title: 'legal',
    extLink: 'https://themelodyapp.com/terms',
  },
  {
    title: 'privacy',
    extLink: 'https://themelodyapp.com/privacy',
  },
  {
    title: 'logout',
    action: 'logout',
    roles: [ROLES.Searcher, ROLES.Maker, ROLES.Admin, ROLES.Influencer],
  },
];
