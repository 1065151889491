// Variables - Styles
const fontFamily = '"Roboto", sans-serif';

const boxShadow = {
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
};
const transition = {
  transition: 'all 0.2s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

// Sizes
const drawerWidth = 300;
const globalIndentXs = 30;
const borderRadiusEllipse = 100;

const layoutHorizontalIndent = {
  paddingLeft: globalIndentXs,
  paddingRight: globalIndentXs,
};
const layoutHorizontalIndentRemove = {
  marginLeft: -globalIndentXs,
  marginRight: -globalIndentXs,
};

// System Color
const whiteColor = '#FFF';
const primaryColor = '#00FFBF';
const primaryColorDark = '#03B689';
const primaryColorDarken = '#039E72';
const secondaryColor = '#374CCD';
const secondaryColorDark = '#26348B';
const dangerColor = '#FA3441';
const warningColor = '#B0A810';
const warningColorDark = '#918910';
const yellowColor = '#EDE336';
const purpleColor = '#A0ADFE';
const blueColor = '#4184F3';
const greyColor = '#575757';
const greyColorDark = '#2F2F2F';
const lightGreyColor = '#979797';
const lightGreyColor2 = '#999999';
const disableColor = '#383837';
const disableTextColor = '#747473';

// Layout Background Color
const bgBody = '#000';
const bgPaper = '#1D1D1B';
const bgPaperMenu = '#454543';
const bgTransparentDark = 'rgba(0, 0, 0, 0.75)';

// Text Color
const textPrimaryColor = '#FFF';
const textSecondaryColor = '#888';
const textBlackColor = '#000';

// Border Color
const borderPrimaryColor = '#353A47';
const borderSecondaryColor = '#494949';
const borderFormColor = '#FFF';

// Table
const tableBorderColor = '#2B2B2B';
const tableBackground = '#2B2B2B';
const tableBackgroundLight = '#4E4E4E';
const tableMaxIndent = 23;

// Button
const buttonMinWidth = 315;

// Player
const playerSize = 300;
const playerPrimaryColor = '#FFF';
const playerSecondaryColor = '#363636';
const playerControlColor = '#B0A810';

//Chart Colors
const colorChartPrimary = '#00FFBF';
const colorChartSecondary = '#374CCD';
const colorChartWarning = '#B0A810';
const colorChartPurple = '#A0ADFE';
const colorChartDanger = '#FA3441';
const colorChartBlue = '#4184F3';

// Swipe
const swipeAnimationInterval = 230;

// Style
const customBrowserScrollBar = {
  //for Firefox only
  scrollbarWidth: 'thin',
  scrollbarColor: `${greyColor} ${lightGreyColor}`,

  '&::-webkit-scrollbar': {
    width: 10,
  },
  '&::-webkit-scrollbar-track': {
    background: lightGreyColor,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: greyColor,
    border: `2px solid ${lightGreyColor}`,
    borderRadius: borderRadiusEllipse,
  },
};

export {
  fontFamily,
  boxShadow,
  transition,
  // Sizes
  globalIndentXs,
  drawerWidth,
  borderRadiusEllipse,
  layoutHorizontalIndent,
  layoutHorizontalIndentRemove,
  // System Color
  whiteColor,
  primaryColor,
  primaryColorDark,
  primaryColorDarken,
  secondaryColor,
  secondaryColorDark,
  dangerColor,
  warningColor,
  warningColorDark,
  yellowColor,
  purpleColor,
  blueColor,
  greyColor,
  greyColorDark,
  lightGreyColor,
  lightGreyColor2,
  disableColor,
  disableTextColor,
  // Layout Background Color
  bgBody,
  bgPaper,
  bgPaperMenu,
  bgTransparentDark,
  // Text Color
  textPrimaryColor,
  textSecondaryColor,
  textBlackColor,
  // Border Color
  borderPrimaryColor,
  borderSecondaryColor,
  borderFormColor,
  // Table
  tableBorderColor,
  tableBackground,
  tableBackgroundLight,
  tableMaxIndent,
  // Button
  buttonMinWidth,
  // Player
  playerSize,
  playerPrimaryColor,
  playerSecondaryColor,
  playerControlColor,
  // Swipe
  swipeAnimationInterval,
  //Chart Colors
  colorChartPrimary,
  colorChartSecondary,
  colorChartWarning,
  colorChartPurple,
  colorChartDanger,
  colorChartBlue,
  // Style
  customBrowserScrollBar,
};
