import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogTitle, IconButton, Typography, DialogTitleProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends DialogTitleProps {
  onClose?: () => void;
  children?: React.ReactNode;
  content?: boolean;
  childClassName?: string;
}

const ModalTitle = ({ onClose, children, content = false, childClassName = '', ...dialogTitleProps }: Props) => {
  const classes = useStyles();

  return (
    <DialogTitle className={classes.modalTitleBlock} disableTypography {...dialogTitleProps}>
      {children && (
        <Typography
          className={cx(content ? classes.modalTitleContent : classes.modalTitle, childClassName)}
          component={content ? 'div' : 'h3'}
          variant={content ? 'body1' : 'h3'}
        >
          {children}
        </Typography>
      )}
      {onClose && (
        <IconButton className={classes.modalCloseButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default memo(ModalTitle);
